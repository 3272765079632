import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { ShopSeedlings, MarketingContainer } from 'components';

const Seedlings = ({ data, location }) => {
  const fields = data.contentfulLandingPage.sections.find((section) => section.contentful_id === '5c4vjnUi0sTPU3Qi0uh51n');
  return (
    <>
      <MarketingContainer mobileLoadingHeight='80vh' />
      <ShopSeedlings location={location} promotionText={fields.body?.body} />
    </>
  );
};

Seedlings.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Seedlings;

export const query = graphql`
  query {
    contentfulLandingPage(contentful_id: { eq: "3kgfvWvLjVO5BnFoQgf3dk" }) {
      sections {
        ... on ContentfulSectionSimpleText {
          id
          name
          body {
            body
          }
          contentful_id
        }
      }
    }
  }
`;
